<template>
  <CCard>
    <!-- Caption -->
    <CCardHeader>
      <slot name="header">{{caption}}</slot>
    </CCardHeader>
    <CCardBody>
      <!-- Table for smaller devices -->
      <div v-if="loading" class="d-block d-md-none" style="position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);"><div role="status" aria-hidden="false" aria-label="Loading" class="spinner-grow spinner-grow-lg text-primary"></div></div>
      <div class="mobile-table d-block d-md-none d-flex flex-column">
        <div class="mobile-block" v-for="(item, index) in items" :key="index">
          <!-- Header -->
          <div class="d-flex mobile-header-row">
            <!-- Title -->
            <span v-if="fields && fields.length" class="d-flex align-items-center flex-grow-1">{{item[fields[0].key]}}</span>
            <!-- Actions -->
            <CButton class="mx-2" v-for="action in actions" :key="action.key" @click="onActionClicked(item, action)">
              <img v-if="action.image" :src="require('@/assets/dashboard/' + action.image)" class="action-img">
            </CButton>
          </div>
          <!-- Body -->
          <div v-if="fields && fields.length > 1" class="d-flex flex-column mobile-content">
            <div v-for="field in fields.filter(function( obj ) { return obj.key !== 'actions';}).slice(1)" :key="field.key" class="d-flex flex-wrap">
              <span class="mobile-content-title">{{field.label}}:</span>
              <span>{{item[field.key]}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Table for bigger devices -->
      <CDataTable class="d-none d-md-block" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" :items="items" :fields="processedFields" :dark="dark" @row-clicked="rowClicked" :loading="loading">
        <!-- Actions -->
        <template v-if="actions.length > 0" #actions="{item}">
          <td class="py-2">
            <CButton class="mx-2" v-for="action in actions" :key="action.key" @click="onActionClicked(item, action)">
              <img :src="require('@/assets/dashboard/' + action.imageBig)" class="action-img">
            </CButton>
          </td>
        </template>
        <template #no-items-view>
          <h2 class="my-5"> 
            <span class="mx-2">Sem resultados</span>
            <svg data-v-31168812="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" class="text-danger mb-2 c-icon c-icon-custom-size" width="30"><path d="M19.955 4.045c-2.036-2.036-4.848-3.295-7.955-3.295-6.213 0-11.25 5.037-11.25 11.25 0 3.107 1.259 5.919 3.295 7.955v0c2.036 2.036 4.848 3.295 7.955 3.295 6.213 0 11.25-5.037 11.25-11.25 0-3.107-1.259-5.919-3.295-7.955v0zM12 2.25c0.003 0 0.006 0 0.009 0 2.431 0 4.653 0.894 6.356 2.37l-0.012-0.010-13.743 13.743c-1.466-1.691-2.36-3.913-2.36-6.344 0-0.003 0-0.006 0-0.009v0c0-5.376 4.374-9.75 9.75-9.75zM12 21.75c-0.002 0-0.005 0-0.008 0-2.419 0-4.632-0.885-6.332-2.349l0.013 0.011 13.739-13.739c1.453 1.687 2.338 3.9 2.338 6.319 0 0.003 0 0.006 0 0.009v-0c0 5.376-4.374 9.75-9.75 9.75z"></path></svg>
          </h2>
        </template>
      </CDataTable>
      <!-- Display number and pagination -->
      <div v-if="displayNumberAndPagination" class="pagination-container d-flex align-items-center flex-wrap">
        <div class="display-number-container d-flex align-items-center my-3 my-lg-0">
          <label class="font-weight-bold secondary-text-color">{{ ('Mostrar') }}</label> 
          <select v-model="itemsPerPage" class="form-control">
            <option value="10" selected="selected" disabled="disabled" hidden="hidden">10</option> 
            <option val="5">5</option>
            <option val="10">10</option>
            <option val="20">20</option>
            <option val="50">50</option>
          </select>
          <label class="font-weight-bold secondary-text-color flex-grow-1">{{ paginationSubtitle }}</label>
        </div>
        <CPagination :active-page.sync="currentPage" :pages="pages" class="my-3 my-lg-0"/>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data () {
    return {
      itemsPerPage: localStorage.itemsPerPage ?? 10,          
      currentPage: 1,
      actionWasClicked: false, // By default, the action button event is propagating to the row,
      sorterValue: this.defaultSorterValue
    }
  },
    props: {
    loading: {
      type: Boolean
    },
    sorter: {
      type: Object,
      default(){
        return null
      }
    },
    defaultSorterValue: {
      type: Object,
      default(){
        return {}
      }
    },
    items: Array,
    pages: {
      type: Number,
      default(){
        return 1
      }
    },
    fields: {
      type: Array,
      default () {
        return []
      }
    },
    actions: {
      type: Array,
      default () {
        return []
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    captionIcon: {
      type: String,
      default: 'cil-grid'
    },
    canDelete: Boolean,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    paginationSubtitle: String,
    displayNumberAndPagination: {
      type: Boolean,
      default: true
    }
  },
  computed:
  {
    processedFields(){
      var result = this.fields;

      if(result.filter(e => e.key === 'actions').length == 0 && this.actions.length > 0)
      {
        result.push({ key: 'actions', label: "Ações", sorter: false },);
      }

      return result;
    }
  },
  watch: 
  { 
    currentPage: function () {
      this.$emit('update-requested', this.currentPage, this.itemsPerPage);
    },
    itemsPerPage: function (val) {
      this.$emit('update-requested', this.currentPage, this.itemsPerPage);
      localStorage.itemsPerPage = val;
    },
    defaultSorterValue: function(){
      this.sorterValue = this.defaultSorterValue;
    },
    sorterValue: function(val){
      this.$emit('sorter-value-changed', val);
    }
  },
  methods:
  {
    reset () {
      this.currentPage = 1;
    },
    rowClicked (item, index) {
      if(!this.actionWasClicked)
      {
        this.$emit('row-clicked', item, index);
        this.actionWasClicked = false;
      }
    },
    onActionClicked(item, action){
      this.actionWasClicked = true;
      this.$emit('action-clicked', item, action);
      const self = this;
      setTimeout(function(){ self.actionWasClicked = false; }, 1000);
    }
  }
}
</script>
<style>
tbody > tr > td 
{
    text-align:center;
}

thead > tr > th 
{
    text-align:center;
}

.display-number-container
{
  gap: 8px;
}

select
{
  width: min-content !important;
}

ul
{
  margin-bottom: 0;
}

.page-item.active .page-link
{
  background-color: rgb(78, 84, 200);
  border-color: rgb(78, 84, 200);
}

.page-link
{
  color: rgb(78, 84, 200);
}

.pagination-container,
.display-number-container
{
  justify-content: center;
}
.action-img{
  height: 20px;
}
@media only screen and (min-width: 576px) {
  .pagination-container,
  .display-number-container
  {
    justify-content: space-between;
  }

  .display-number-container
  {
    flex-grow: 1;
  }
}

/* Mobile blocks */
.mobile-table
{
  min-height: 100px;
}

.mobile-block
{
  border: 1px solid lightgray;
  margin-bottom: 2rem;
}

.mobile-header-row,
.mobile-content
{
  padding: 1rem;
}

.mobile-header-row
{
  background-color: rgb(160, 165, 250);
  color: white;
  font-weight: bold;
}

.mobile-content-title
{
  margin-right: 1rem;
  font-weight: bold;
}
</style>
